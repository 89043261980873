<template>
	<div class="helo">
		<h1>haha</h1>
	</div>
</template>

<script>
	export default {
		name:"Haha"
	}
</script>

<style>
</style>
